#hero {
    width: 100%;
    height: 80vh;
    overflow: hidden;
    position: relative;
    background: url("hero-bg.jpg") top center;
    background-size: cover;
    position: relative;
    margin-bottom: -90px;
}

#hero:before {
    content: "";
    background: rgba(6, 101, 183, 0.9);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

#hero h2 {
    color: #fff;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 700;
}

#hero p {
    width: 80%;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    margin: 0 auto 30px auto;
    color: #fff;
}

#hero .carousel-control-prev, #hero .carousel-control-next {
    width: 10%;
}

#hero .carousel-control-next-icon, #hero .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
}

#hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #f6b024;
}

#hero .btn-get-started:hover {
    background: #f6b024;
    color: #fff;
    text-decoration: none;
}

@media (min-width: 1024px) {
    #hero p {
        width: 60%;
    }
    #hero .carousel-control-prev, #hero .carousel-control-next {
        width: 5%;
    }
}

@media (max-width: 768px) {
    #hero {
        height: 100vh;
        margin-bottom: 60px;
    }
    #hero h2 {
        font-size: 28px;
    }
}
