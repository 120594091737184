/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    background: #05579e;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
}

#footer .footer-newsletter {
    padding: 50px 0;
    background: #05579e;
}

#footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
}

#footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
}

#footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 4px;
    width: calc(100% - 100px);
}

#footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    margin: 3px;
    background: #f6b024;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
}

#footer .footer-newsletter form input[type="submit"]:hover {
    background: #0880e8;
}

#footer .footer-top {
    background: #065fad;
    padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
    font-size: 18px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
}

#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #fff;
}

#footer .footer-top .social-links a {
    font-size: 16px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    line-height: 1;
    padding: 10px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: #f6b024;
    color: #fff;
    text-decoration: none;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #5db1f9;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: rgba(255, 255, 255, 0.75);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    color: #fff;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
    line-height: 26px;
}

#footer .copyright {
    text-align: center;
    padding-top: 30px;
}

#footer .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #fff;
}

#footer .credits a {
    color: #f6b024;
}
