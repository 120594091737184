
.about {
    padding: 140px 0;
    background: url("about-bg.png") top center no-repeat;
    position: relative;
}

.about:before {
    content: "";
    background: rgba(255, 255, 255, 0.75);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
}

.about .container {
    position: relative;
}

.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-left: 28px;
    position: relative;
}

.about .content ul li + li {
    margin-top: 10px;
}

.about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #0880e8;
    line-height: 1;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about .content .btn-learn-more {
    font-family: "Raleway", sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #0880e8;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #0880e8;
}

.about .content .btn-learn-more:hover {
    background: #0880e8;
    color: #fff;
    text-decoration: none;
}

@media (max-width: 992px) {
    .about {
        padding: 60px 0;
    }
}
