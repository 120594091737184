.services .icon-box {
    margin-bottom: 20px;
    padding: 50px 40px;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.services .icon-box i {
    float: left;
    color: #f6b024;
    font-size: 40px;
}

.services .icon-box h4 {
    margin-left: 70px;
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}

.services .icon-box h4 a {
    color: #05579e;
    transition: 0.3s;
}

.services .icon-box h4 a:hover {
    color: #0880e8;
}

.services .icon-box p {
    margin-left: 70px;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}
