/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
    height: 60px;
    font-size: 14px;
    transition: all 0.5s;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.8);
}

#topbar.topbar-scrolled {
    top: -60px;
}

#topbar .contact-info ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

#topbar .contact-info li {
    display: inline-block;
    padding: 0;
}

#topbar .contact-info li + li {
    margin-left: 18px;
}

#topbar .contact-info a {
    color: rgba(255, 255, 255, 0.8);
    transition: 0.3s;
}

#topbar .contact-info a:hover {
    color: #fff;
}

#topbar .contact-info i {
    color: #f6b024;
    padding-right: 4px;
}

#topbar .cta {
    background: transparent;
}

#topbar .cta a {
    color: #fff;
    background: #f6b024;
    padding: 6px 24px 8px 24px;
    display: inline-block;
    transition: 0.3s;
    border-radius: 50px;
}

#topbar .cta a:hover {
    background: #f1a40a;
}
